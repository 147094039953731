<template>
    <main data-aos="fade-up" data-aos-duration="1000">
        <!-- hero-area start -->
        <section class="breadcrumb-bg pt-100 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 col-md-9 col-12">
                        <div class="page-title">
                            <h1>
                                Health Travel Secure S.A.S.<br>
                                Terms of Use
                            </h1>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                        <div class="page-breadcumb">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb ">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Home</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Terms of Service</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="terms-of-service pt-100 pb-100">
            <div class="container">
                <div class="text-wrapper">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="text-center mb-50">PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OUR PLATFORM</h4>

                            <p>
                                These terms of use (the “<b>Terms</b>”) are a legal agreement between you (“<b>you</b>”) and <b>Health Travel Secure S.A.S.</b> (“<b>HTS</b>”, “<b>us</b>”, or “<b>we</b>”) with respect to your use of HTS’s services and platform associated with website [www.healthtravelsecure.com.] (the “<b>Platform</b>”).
                            </p>

                            <p>
                                <b>By clicking on the "Accept" button below you agree to these Terms. If you do not agree to these Terms, we do not grant you permission to use the Platform and you must not use it.</b>
                            </p>

                            <p>
                                You must be over 18 years old to use the Platform.
                            </p>

                            <p>
                                [These Terms are only available in the English language.] You should also print a copy of these Terms for your future reference.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">1.  	YOUR ACCESS TO THE PLATFORM</h4>

                            <p>
                                These Terms apply to the Platform including any updates or supplements, unless new terms are provided, in which case those terms apply. We may change these Terms at any time by notifying you of a change when you next access the Platform (we may also email you about any material changes to the Terms). The new terms may be displayed on-screen and you may be required to agree to them to continue your use of the Platform. The date these Terms were last updated appears at the bottom of these Terms.
                            </p>
                            <br><br>

                            <p>
                                The Platform and associated services are in a continuous state of development, which coincides with the growth of the business, and may expand to include other applications, services, and websites.
                            </p>

                            <br><br>

                            <p>
                                Material updates or supplements to these Terms may also be transmitted electronically to the client on a case by case basis as time permits to accommodate the particular set of circumstances of a client’s situation
                            </p>

                            <br><br>

                            <p>
                                From time to time we may issue updates to the Platform and its related services and applications. Depending on the update, use of the Platform may be restricted until you have downloaded and installed the latest version of the Platform and accepted any new terms where necessary. Some updates may not be available on certain computers and handheld devices. In order to use the Platform and its related services and applications, you may be required to obtain certain updates and/or upgrades to your computer or handheld device. You are responsible for any costs and/or fees associated with any such updates/upgrades. You also understand and accept that (a) the device you use to access the Platform and its related services may require certain software in order for the Platform to work, and it is your responsibility to ensure that you have the required up-to-date software, and internet connectivity, and (b) the Platform has not been developed to meet your individual requirements, and it is therefore your responsibility to ensure that the functionality of the Platform and its related services meet your requirements.
                            </p>

                            <br><br>

                            <p>
                                Where download and installation is necessary to use aspects of the Platform and its related services, you may only download and install such aspects onto a device that you own, or if you do not own the device, you must have the permission from the owner(s) to do so. You accept responsibility under these Terms for the use of the Platform, whether or not you own the device onto which aspects of the Platform are downloaded and installed. You also acknowledge that the owner(s) of the device onto which aspects of the Platform are installed may be charged by any relevant mobile network operator and/or internet service provider in relation to any data and/or mobile connectivity used by the Platform and its related services.
                            </p>

                            <br><br>

                            <p>
                                Access to the Platform may be suspended temporarily and without notice (a) in the case of system failure, (b) for maintenance or repair, (c) where we reasonably suspect there has been a breach of these Terms, (d) for reasons reasonably beyond our control, (e) due to a unilateral decision of HTS, and/or (d) as otherwise explained in these Terms.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">2.  	PERMITTED USE AND RESTRICTIONS</h4>

                            <p>
                                In consideration of you agreeing to abide by these Terms, we grant you a non-transferable, non-exclusive access to the Platform, subject to these Terms. We reserve all other rights, which are not granted in these Terms. You may use the Platform for your personal purposes only. We reserve the right to modify or discontinue the Platform at any time for any reason without notice.
                            </p>

                            <br><br>

                            <p>
                                Except as explained in these Terms or as permitted by any applicable local law, you agree: (a) not to copy the Platform except where such copying is incidental to normal use of the Platform, (b) not to rent, lease, sub-license (i.e. grant anyone else the permission to use the Platform), loan, translate, merge, adapt, vary or modify the Platform (or any part of it), (c) not to, nor attempt to, disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the Platform, and (d) not to provide or otherwise make available the Platform in whole or in part in any form to any person without prior written consent from us.
                            </p>

                            <p>
                                You must not use the Platform: (a) in any unlawful manner, for any unlawful purpose, or to act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, into the Platform or any operating system used by the Platform, (b) in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users, (c) to collect or harvest any information or data from the Platform or our systems or attempt to decipher any transmissions to or from the servers running the Platform, (d) to send, knowingly receive, upload, download, use or re-use any material which does not comply with these Terms, or (e) to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).
                            </p>

                            <br><br>

                            <p>
                                We have reserved the right to cancel your account or forbid your access to The Platform if you incur in any of the unlawful or inadequate use, as we previously described, that violate these “Terms” or that breach the acquired obligations.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">3.  	ENROLLING AND CREATING YOUR ACCOUNT</h4>

                            <p>
                                The Platform enables you to use and pay for administrative, logistic and transportation services offered by HTS as a medical facilitator, through the Platform (the “<b>Services</b>”).
                            </p>

                            <br><br>

                            <p>
                                To use the Platform and contract the Services, you need to create a user account. This can be created via the Platform by providing some combination of your personal details, including your email address, mobile telephone number, driver’s licence information and credit or debit card details. Once you have enrolled, the information you provide will undergo a validation process, details of which will be provided to you through email messages. Upon successful completion of the enrolment process, we will allocate to you a user account protected by a password of your choice (your “<b>Account</b>”). You will be notified of your Account status and details through subsequent email messages.
                            </p>

                            <br><br>

                            <p>
                                We shall not be responsible for any losses arising out of the unauthorised or improper use of your Account that we do not warrant.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">4.  	SERVICES</h4>

                            <p>
                                As a medical facilitator based in Medellín Colombia, we list and promote medical and pharmaceutical services to be provided by third parties (the “<b>Third Party Services</b>”) and we provide administrative, logistic, and transportation services to access the Third-Party Services.
                            </p>

                            <br><br>

                            <p>
                                HTS does not provide medical or pharmaceutical services and therefore, does not have any liability or responsibility regarding the Third-Party Services.
                            </p>

                            <br><br>

                            <p>
                                Considering the above, you accept that HTS has no liability nor responsibility regarding the Third Party Services and releases HTS from any liability or responsibility or damages caused in connection with the Third Party Services.
                            </p>

                            <br><br>

                            <p>
                                You agree to indemnify and hold HTS and their respective affiliates, predecessors, successors and assigns (and their respective officers, directors, employees and agents) harmless from and against any and all liabilities, and/or damages related to the Third-Party Services.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">5.  	PAYMENT AND PAYMENT TERMS</h4>

                            <p>
                                The Services and the Third-Party Services contracted through the Platform, will be paid by you following the prices and costs listed in future communications to you from HTS and/or provided by the third parties providing the Third Party Services. No payment shall be made to the third parties in connection with the Third Party Services. All payments must be made through the Platform.
                            </p>

                            <br><br>

                            <p>
                                The charges, prices and costs may be modified or updated by us from time to time. It is your responsibility to remain informed about the current rates for the Services and the Third Party Services.
                            </p>

                            <br><br>

                            <p>
                                You will make payment to us in respect of the Services. You agree that we may charge your payment card for the Services you contract with your Account and according to the prices, charges, and costs listed in future communications from HTS. The card associated with your Account will be charged each time you use your Account to contract Services (including any taxes, service fees, transaction costs, bank fees, late fees, and penalties as applicable). You are responsible for providing us with a valid payment card account for the payment of all charges at all times.
                            </p>

                            <br><br>

                            <p>
                                We use a third party payment processor to link your payment card to the Platform and Services through your Account (“<b>Payment Processor</b>”). The processing of payments in connection with your use of the Platform and the Services will be subject to the terms, conditions and policies of the Payment Processor and your card issuing bank in addition to these Terms. We are not responsible for any errors by the Payment Processor, nor are we in anyway involved in the issuing of credit or assessing eligibility for credit.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">6.  	RIGHT OF RETRACTION</h4>

                            <p>
                                According article 47 of Law 1480 of 2011 (The Consumer Statute), once the payment is made if the service has not started to be provided within five (5) business days, you are entitled to the right of retraction, in the event you exercise your right, the contract will end and we will refund the price paid within the next thirty (30) calendar days.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">7.  	SECURITY AND PASSWORDS</h4>

                            <p>
                                You are solely responsible for maintaining the security of your passwords. You must keep your password and any other authentication information confidential. You must not disclose it to anyone else. If you know or suspect that anyone other than you knows your password or any other authentication information, you must promptly notify us using the contact details below. You should also contact your issuing bank in order to prevent any unauthorized use of your payment card. We are not responsible for any losses or liabilities arising out of or in connection with any unauthorized use of the Platform and your Account.
                            </p>

                            <br><br>

                            <p>
                                We have the right to disable any password, or other authentication information whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">8.  	YOUR PROMISES TO US</h4>

                            <p>
                                You promise to us that (a) you are authorized to agree to these Terms, (b) you are not located in a country subject to a United States government embargo, nor are you located in a country that has been designated by the United States government as a “terrorist supporting” country, (c) you are not listed on any United States government list of prohibited or restricted parties, (d) any information submitted by you shall be current, accurate and complete and shall be submitted at your own risk, and (e) you will not disclose nor allow to be disclosed by any means any confidential information belonging to us that you become aware of.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">9.  	PRIVACY</h4>

                            <p>
                                Parties shall treat as confidential all information made available to them through the Platform or else, and shall not disclose such confidential information without the written consent of the owner of that information. However, HTS will use and is authorized herein by you to use the information of your payment card and the information contained herein, (i) to provide you the Services; and (ii) to share it with the Third Parties that will provide you with Third Party Services.
                            </p>

                            <br><br>

                            <p>
                                To facilitate your use of the Platform and determine the eligibility of your card, we will share your payment card details and transaction details with other third parties such as those assisting us with transaction processing.
                            </p>

                            <br><br>

                            <p>
                                The foregoing restriction on use and disclosure shall not apply to confidential information which, at the time of disclosure or its becoming known to the recipient, the recipient can show (i) is public knowledge; or (ii) came lawfully into the recipient's possession otherwise than directly or indirectly from the owner without restriction on its subsequent disclosure or use by the recipient.
                            </p>

                            <br><br>

                            <p>
                                For the Platform to function correctly, we may collect, use, and share precisely your geographical location data.
                            </p>

                            <br><br>

                            <p>
                                By agreeing to these Terms, you are agreeing to and you consent to (i) the processing of your personal information as explained above; and (ii) the collection of information by the Platform as explained above.
                            </p>

                            <br><br>

                            <p>
                                The parties of these Terms have declared that they have provided personal information voluntarily and with the informed consent needed for the correct execution of these services in relation, to compliance with the purposes established by the National Constitution, Law 1581 of 2012, the Complementary Decree 1377 of 2013 and any other applicable Colombian legislation, and especially, to comprehensively develop the object of these Terms. The rights of the Parties, as the owners of the information, are the ones ruled in the National Constitution and in the law, the parties especially have the right to know, update, correct and delete any of their personal information, also they have the right to revoke the consent given for the treatment of their personal information with the legal exceptions. The rights of the parties as owners of their personal data can be exercised throughout the channels of communication established between the parties. The parties are obliged to guarantee the confidentiality, liberty, security, veracity, transparency, access and restricted circulation of the provided personal data.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">10.   INTELLECTUAL PROPERTY RIGHTS</h4>

                            <p>
                                You acknowledge that all intellectual property rights related to the Platform and us, anywhere in the world, belong to us, and that you have no rights in, or to, the Platform other than the right to use it in accordance with these Terms. It is expressly forbidden to register directly, or thorough a third party the name of Health Travel  Secure (HTS), or to register the commercial names, brands, trademarks or logotypes with similar colours or with equal content making the public confused.
                            </p>

                            <br><br>

                            <p>
                                In the event that anyone brings a claim that the Platform or any part of it, or your possession and/or use, infringes a third party’s intellectual property rights, we shall be responsible for the investigation, defense, settlement and discharge of any such claim.
                            </p>

                            <br><br>

                            <p>
                                It is expressly forbidden for you to register directly, or thorough a third party the name of HTS, or to register the commercial names, brands, trademarks or logotypes with similar colours or with equal content making the public confused.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">11.   DISCLAIMER AND LIMITATION OF LIABILITY</h4>

                            <p>
                                HTS provides administrative, logistical, scheduling, and other services as a facilitator of medical services, through the Platform. The Company does not provide or perform medical, dental, surgical, clinical, cosmetic, or aesthetic procedures or treatments.
                            </p>

                            <br><br>

                            <p>
                                We provide the Platform on an “as is” and “as available” basis. We make no guarantee that the Platform will be uninterrupted, error free, or free from viruses or other harmful components. While we take reasonable precautions to prevent the existence of computer viruses and/or other malicious programs, we accept no liability for them. To the extent permitted by law, we exclude all conditions, warranties, representations or other terms, which may apply to the Platform, whether express or implied.
                            </p>

                            <br><br>

                            <p>
                                We only supply the Platform and our Services for personal and private use. You agree not to use the Platform for any commercial, business or resale purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
                            </p>

                            <br><br>

                            <p>
                                Our maximum total liability under or in connection with these Terms and the Services whether in contract, tort (including negligence) or otherwise, shall in all circumstances be [excluded to the maximum extent permitted by applicable law OR limited to US $100]. However, HTS, under no circumstance shall be liable in connection with the Third Party Services.
                            </p>

                            <br><br>

                            <p>
                                Nothing in these Terms will limit or exclude our liability for resulting from our wilful misconduct.
                            </p>

                            <br><br>

                            <p>[Nothing in these Terms restricts any legal rights you may have as a consumer.]</p>

                            <br><br>

                            <h4 class="mt-20 mb-10">12.   APPLICABLE LEGISLATION</h4>

                            <p>
                                Since the medical services are provided in Colombia, these Terms invoke the Colombian law, therefore, its acceptation implies that the contractual relation that emerges from this document will be ruled, in all its effects by its content and by the applicable Colombian legislation.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">13.   TERMINATION</h4>

                            <p>
                                We may terminate these Terms and your permission to use the Platform immediately if (a) you commit any breach of these Terms; (b) we discontinue the Platform or the provision of our Services; or (c) we are prevented from providing the Services for any reason.
                            </p>

                            <br><br>

                            <p>
                                Furthermore, we reserve the right to change, edit, suspend delete and/or cancel any part of the Platform and to modify the Services offered in our Platform and/or your access to the Platform and therefore our Services, at any time with or without notice to you: (a) if required by law, (b) due to an event beyond our control; or (c) as a result of changes, cancellations or revocation of approvals by third parties, including Third Party Services providers and/or authorities.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">14.   COMMUNICATION BETWEEN US</h4>

                            <p>
                                If you wish to contact us in writing, or if any condition in these Terms require you to give us notice in writing, you can send this to us by e-mail at info@healthtravelsecure.com. If we have to contact you or give you notice in writing, we may do so by e-mail or using any other contact details you provide to us.
                            </p>

                            <br><br>

                            <h4 class="mt-20 mb-10">15.   OTHER IMPORTANT TERMS</h4>

                            <p>
                                You authorize us to transfer and or assign our rights and obligations under these Terms to another company or organisation, in which case you will be notified. However, assignment of our rights under these Terms shall not affect your rights or obligations under these Terms.
                            </p>

                            <br><br>

                            <p>
                                If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce any of our rights against you, or if we delay in doing so, that will not mean that we have waived any of our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.
                            </p>

                            <br><br>

                            <p>
                                Each of the conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect. Other than as expressly set out in these Terms, no one other than you and us is intended to have any right or ability to enforce any of the provisions of these Terms.
                            </p>

                            <br><br>

                            <p>These Terms were last updated September 15, 2020</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    export default {
        name: "terms-of-service",
        created() {
            window.scrollTo(0, 0);
        }
    }
</script>

<style scoped>
    .breadcrumb-bg {
        background-image: url("../assets/img/services-banner.png");
        background-position: top;
        position: relative;
    }

        .breadcrumb-bg::after {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba(255, 255, 255, 0.72);
        }

        .breadcrumb-bg .container {
            position: relative;
            z-index: 2;
        }

    .text-wrapper {
        background-color: #fff;
        padding: 50px 80px;
        box-shadow: 0 0 12px -3px #e8e8e8;
    }

        .text-wrapper h4 {
            width: 100%;
            margin-bottom: 30px;
            color: #223645;
        }

    @media (max-width: 768px) {
        .text-wrapper {
            padding: 50px 30px;
        }
    }
</style>
